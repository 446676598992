<template>
	<div class="position-relative">
		<img :src="imgPortada" class="img-background w-100" style="height: 300px;">
		<div class="position-absolute portada object-position-top text-white">
			<div class="h-100 d-flex align-items-center justify-content-center">
				<h1 class="playfairDisplay" v-text="title"></h1>
			</div>
		</div>
	</div>
	<div>
		<div class="form-row justify-content-center py-5">
			<div class="col-lg-11">
				<h1 class="text-center playfairDisplay mb-0" style="font-weight: 400; color: #160808;">
					JAMÁS HABÍA SIDO TAN FÁCIL
				</h1>
				<h4 class="d-flex align-items-center justify-content-center text-muted playfairDisplay mb-0" style="font-weight: 400;">
					<span class="me-2" style="display: inline-block; width: 30px; height: 1px;background-color: #ccc;"></span>
					DAR & RECIBIR REGALOS
					<span class="ms-2" style="display: inline-block; width: 30px; height: 1px;background-color: #ccc;"></span>
				</h4>
				<p class="text-center fs-5 text-muted mt-4 playfairDisplay">Para OURGIFT es importante hacer de este momento una experiencia inolvidable y que te facilite la organización de tu evento.  Es por ello que en este espacio encontrarás una serie de proveedores en donde podrás escoger lo que necesitas y deseas que te obsequien o regalen.</p>
			</div>
		</div>

		<div class="bg-rose py-5">
			<div v-if="loadingProductos" :style="{cursor:cursorCarousel}">
				<carousel :items-to-show="3" :wrapAround="true" v-if="proveedores.length>0">
					<slide v-for="(proveedor, index) in proveedores" :key="index" class="p-3">
						<router-link :to="{name: 'productos.index', query: {proveedor: proveedor.CODIGO_PROVEEDOR}}" :title="proveedor.NOMBRE_PROVEEDOR">
							<div class="h-100 d-flex flex-column">
								<div class="mb-2 mt-auto">
									<span v-if="proveedor.adjuntos && proveedor.adjuntos.length>0">
										<img :src="proveedor.adjuntos[0].ARCHIVO" class="img-fluid w-100">
									</span>
									<div class="w-75 mx-auto" v-else>
										<img :src="emptyIMG" class="img-fluid">
									</div>
								</div>
								<div class="mt-auto text-center">
									{{proveedor.NOMBRE_PROVEEDOR}}
								</div>
							</div>
						</router-link>
					</slide>
				</carousel>
			</div>
			<div class="text-center" v-else>
				<div class="spinner-grow text-primary" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgPortada from '@/assets/img/backgrounds/contacto.jpg'
	import emptyIMG from '@/assets/img/empty-image.png'
	import cusorImg from '@/assets/img/drag-cursor.png'
	import 'vue3-carousel/dist/carousel.css'
	import { Carousel, Slide } from 'vue3-carousel'

	export default {
		components: {
			Carousel,
			Slide
		},
		data(){
			return {
				imgPortada,
				emptyIMG,
				cusorImg,
				cursorCarousel: `url('${cusorImg}'), auto`,
				loadingProductos: false,
				proveedores: []
			}
		},
		mounted(){
			this.getProveedores()
		},
		methods: {
			getProveedores(){
				this.loadingProductos = true
				this.$store.dispatch('getProveedores', {
					pagination: 0,
					ESTADO: 1
				}).then(res => {
					this.proveedores = res.data || []
					return res
				}).catch(err => {
					return err
				}).catch(() => {
					this.loadingProductos = false
				})
			},
		},
		computed: {
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},
		}
	}
</script>